<template>
  <auth-container>
    <CImage
      src="/images/success-iso.png"
      alt="success"
      pos="relative"
      zIndex="1"
      mx="auto"
      mb="30px"
    />
    <CHeading
      as="h1"
      color="brand.900"
      fontSize="36px"
      fontWeight="600"
      marginBottom="30px"
    >
      Selamat
    </CHeading>
    <CText color="darkGray.900" fontSize="18px" lineHieght="27px">
      Pendaftaran Anda berhasil, silakan cek email Anda untuk melakukan
      verifikasi email
    </CText>
    <CButton as="router-link" to="/" variant="outline" variant-color="brand" rounded="full" mt="40px">
      Homepage <CImage ml="10px" :src="require('@/assets/icon-chevron-right-green.svg')" />
    </CButton>
  </auth-container>
</template>

<script>
import AuthContainer from "@/components/auth-container";

export default {
  name: "SuksesPage",
  components: {
    AuthContainer,
  },
  data() {
    return {
      showPassword: false,
    };
  },
};
</script>

<style>
</style>
